import React from "react"
import P from "../../core/P"
import H3 from "../../core/H3"
import Icon from "../../core/Icon"

export const Centered = ({ feature, size, theme }) => {
  const SectionA = e => (
    <>
      {e.fontello && (
        <Icon
          iconName={e.fontello}
          className="text-left flex justify-start pb-5 mx-0"
          theme={theme}
        />
      )}
      {e.icon && (
        <div className={`${e.title ? "pb-5" : " "} h-24`}>
          <div className="w-16 rounded-md text-white">
            <div className="w-full h-auto">
              <img
                src={e.icon?.mediaItemUrl}
                loading="lazy"
                height={e.icon?.mediaDetails?.height}
                width={e.icon?.mediaDetails?.width}
                alt={e.icon?.altText ? e.icon?.altText : e.title}
                // title={e.title}
                className={`object-contain text-center`}
              />
            </div>
          </div>
        </div>
      )}
      {e.title && (
        <H3
          title={e.title}
          className={`font-bold text-left text-shark-400 ${e.description ? "pb-5" : " "
            }`}
          textSize="text-lg"
        />
      )}
      {e.description && (
        <P title={e.description} className={`text-left text-shark-400`} noPad />
      )}
    </>
  )
  const SectionB = e => (
    <>
      {e.fontello && (
        <i
          className={`${e?.fontello} text-left flex justify-start pb-5 mx-0 text-blue-500 icon `}
          style={{ fontSize: "70px" }}
        />
      )}
      {e.icon && (
        <div className={`${e.title ? "pb-5" : " "} h-24`}>
          <div className="w-16 rounded-md text-white">
            <div className="w-full h-auto">
              <img
                src={e.icon?.mediaItemUrl}
                loading="lazy"
                height={e.icon?.mediaItemUrl?.height}
                width={e.icon?.mediaItemUrl?.width}
                alt={e.icon?.altText ? e?.icon?.altText : e?.title}
                // title={e.title}
                className={`object-contain text-center`}
              />
            </div>
          </div>
        </div>
      )}
      {e.title && (
        <H3
          title={e.title}
          className={`font-bold text-left  ${e?.description ? "pb-5" : " "}`}
          textSize="text-lg"
        />
      )}
      {e.description && (
        <P title={e?.description} className={`text-left `} noPad />
      )}
    </>
  )
  return (
    <div className="flex justify-center bg-blue-200 ">
      <div className="px-4 sm:px-6 w-screen lg:px-12  ">
        <div
          className={`lg:grid lg:grid-cols-${size} lg:gap-6 `}
          data-aos="fade-up"
        >
          {feature.map(e => {
            const pointer = e.cta?.url ? "cursor-pointer" : "cursor-default"
            return theme ? (
              <div
                className={`shadow-lg text-center p-6 my-6 lg:my-0 bg-white rounded-md ${e.icon
                  ? "transition border-t-4 border-white hover:shadow-xl hover:-translate-y-1"
                  : "transition duration-200 ease-in-out icon-card"
                  } `}
                key={Math.random()}
              >
                {e.cta?.url ? (
                  <a
                    href={e.cta?.url ? e.cta.url : "#"}
                    className={`${pointer}`}
                  >
                    {SectionA(e)}
                  </a>
                ) : (
                  <>{SectionA(e)}</>
                )}
              </div>
            ) : (
              <div
                className={`shadow-lg text-center p-6 my-6 lg:my-0 bg-white rounded-md ${e.icon
                  ? "transition border-t-4 hover:border-blue-500 border-white hover:shadow-xl hover:-translate-y-1 text-shark-400"
                  : "hover:bg-blue-500 hover:shadow-xl text-shark-400 hover:text-white transition duration-200 ease-in-out icon-card"
                  } `}
                key={Math.random()}
              >
                {e.cta?.url ? (
                  <a
                    href={e.cta?.url ? e.cta.url : "#"}
                    className={`${pointer}`}
                  >
                    {SectionB(e)}
                  </a>
                ) : (
                  <>{SectionB(e)}</>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Centered
